import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  TablePagination,
} from "@mui/material";
import {
  DeleteOutlined,
  SearchOutlined,
  SendOutlined,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NumberFormat from "../helpers/functions/numberFormat";
import moment from "moment";
import EmptyTableList from "./base/EmptyTableList";
import { tableHeadVariantDetail } from "../utils/Constants";
import TableList from "./base/TableList";
import TransactionPDF from "../helpers/functions/generatePdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

function ModalDetail({
  isVisibleDetail,
  transactionSelected,
  detailTransaction,
  detailProduct,
  onClose,
  type,
  fullScreen,
  onCancel,
  onHandleFinishOrder,
  isProductReceive,
  isAmount,
  isDownloadPdf,
  grandTotal,
  totalRecordDetailSO,
  rowsPerPageDetailSO,
  pageDetailSO,
  handleChangePageDetailSO,
  handleChangeRowsPerPageDetailSO,
}) {
  const [scroll, setScroll] = React.useState("paper");

  const renderDetail = () => {
    if (type === "product-detail") {
      return (
        <>
          <DialogTitle sx={{ mb: 2 }}>Detail Product</DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
                    Product Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {detailProduct?.productName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
                    Category Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {detailProduct?.categoryName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
                    Brand Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {detailProduct?.brandName}
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                sx={{
                  mt: 4,
                  fontWeight: "semibold",
                  fontSize: 16,
                }}
              >
                Variant Detail List
              </Typography>
              <Divider
                sx={{
                  paddingTop: "5px",
                }}
              />
              {detailProduct?.attachments ? (
                <>
                  <TableList
                    tableStructure="variant-list"
                    tableName={"Variant List"}
                    tableHeadList={tableHeadVariantDetail}
                    data={detailProduct?.attachments}
                    isShowPagination={false}
                  />
                </>
              ) : (
                <EmptyTableList
                  colSpan={tableHeadVariantDetail.length}
                  tableName={"Variant Product"}
                />
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      );
    } else if (type === "history-detail") {
      return (
        <>
          <DialogTitle sx={{ mb: 2 }}>Detail Product</DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
                    Product Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {detailProduct?.productName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
                    Category Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {detailProduct?.categoryName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
                    Brand Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {detailProduct?.brandName}
                  </Typography>
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                sx={{
                  mt: 4,
                  fontWeight: "semibold",
                  fontSize: 16,
                }}
              >
                History Detail List
              </Typography>
              <Divider
                sx={{
                  paddingTop: "5px",
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      );
    } else if (type === "stock-opname") {
      return (
        <>
          <DialogTitle sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Detail Stock Opname</span>
              {/* <PDFDownloadLink
                document={
                  <TransactionPDF
                    transactionSelected={detailTransaction}
                    detailTransaction={detailTransaction}
                  />
                }
                fileName="stock-opname-detail.pdf"
                style={{ textDecoration: "none" }}
              >
                {({ loading }) =>
                  loading ? (
                    <Button disabled>Loading Document...</Button>
                  ) : (
                    <Button variant="contained" color="primary">
                      Export PDF
                    </Button>
                  )
                }
              </PDFDownloadLink> */}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} sx={{ marginTop: 2 }}>
                  <Typography variant="subtitle1">Outlet Name:</Typography>
                  <Typography variant="body2">
                    {detailTransaction?.branchName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: 2 }}>
                  <Typography variant="subtitle1">
                    Stock Opname Date:
                  </Typography>
                  <Typography variant="body2">
                    {moment(detailTransaction?.createdDate).format(
                      "DD MMM YYYY, h:mm"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ mt: 4 }}>
                Stock Opname Product Details
              </Typography>
              <Divider
                sx={{
                  paddingTop: "5px",
                }}
              />
              {detailTransaction?.attachments && (
                <>
                  <Typography variant="h6" sx={{ mt: 4 }}>
                    Items
                  </Typography>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">No</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>{`Total Stock(System)`}</TableCell>
                          <TableCell>{`Total Stock(Actual)`}</TableCell>
                          <TableCell>{`Total Diffrence`}</TableCell>
                          <TableCell>{`Price`}</TableCell>
                          <TableCell>{`Total`}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailTransaction.attachments.map(
                          (itemData, index) => (
                            <TableRow key={itemData.id}>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell>{itemData.productName}</TableCell>
                              <TableCell>
                                {`${NumberFormat(
                                  0,
                                  itemData.inventoryQuantity
                                )} pcs`}
                              </TableCell>

                              <TableCell>
                                {`${NumberFormat(
                                  0,
                                  itemData.stockOpnameQuantity
                                )} pcs`}
                              </TableCell>

                              <TableCell>
                                {`${
                                  itemData.stockOpnameTypeCode == "TYPEB"
                                    ? "-"
                                    : ""
                                } ${NumberFormat(
                                  0,
                                  itemData.totalProductDiffrence
                                )} pcs`}
                              </TableCell>

                              <TableCell>{`Rp. ${NumberFormat(
                                0,
                                itemData.trxPrice
                              )}`}</TableCell>

                              <TableCell>{`Rp. ${NumberFormat(
                                0,
                                itemData.stockOpnamePrice
                              )}`}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                      <TableRow sx={{ "& > td": { borderBottom: "none" } }}>
                        <TableCell colSpan={5} />
                        <TableCell rowSpan={5}>
                          <Typography sx={{ fontSize: "18px" }}>
                            Grand Total
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", fontSize: "18px" }}
                          align="left"
                        >
                          {`Rp. ${grandTotal}`}
                        </TableCell>
                      </TableRow>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={
                        totalRecordDetailSO
                          ? totalRecordDetailSO
                          : detailTransaction?.attachments.length
                      }
                      rowsPerPage={rowsPerPageDetailSO}
                      page={pageDetailSO}
                      onPageChange={handleChangePageDetailSO}
                      onRowsPerPageChange={handleChangeRowsPerPageDetailSO}
                    />
                  </TableContainer>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      );
    } else if (type === "closing-detail") {
      return (
        <>
          <DialogTitle sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Detail Balance</Typography>
              {/* <PDFDownloadLink
                document={
                  <TransactionPDF
                    transactionSelected={detailTransaction}
                    detailTransaction={detailTransaction}
                  />
                }
                fileName={`${transactionSelected.branchName}-balance.pdf`}
                style={{ textDecoration: "none" }}
              >
                {({ loading }) =>
                  loading ? (
                    <Button disabled>Loading Document...</Button>
                  ) : (
                    <Button variant="contained" color="primary">
                      Export PDF
                    </Button>
                  )
                }
              </PDFDownloadLink> */}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              <Grid container spacing={1}>
                <Grid container sx={{ padding: "10px" }}>
                  <Grid item xs={12} sm={3} sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">Grand Total</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        {`Rp. ${NumberFormat(
                          0,
                          detailTransaction?.tottalExpanseSelling
                        )}`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">Outlet Name</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        {transactionSelected.branchName}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">Closing Date</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        {moment(transactionSelected.endDate).format(
                          "DD MMM YYYY, hh:mm"
                        )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={3} sx={{ marginTop: 2 }}>
                    <Typography variant="subtitle1">Closing Id</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        {detailTransaction?.clossingId}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ mt: 4 }}>
                Transaction Details
              </Typography>
              <Divider
                sx={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              />
              <Grid sx={{ padding: "10px" }} container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1">Cash Transaction</Typography>
                  <Typography variant="h5" color="primary">
                    {`Rp. ${NumberFormat(0, detailTransaction?.cash)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1">Non Cash Transaction</Typography>
                  <Typography variant="h5" color="primary">
                    {`Rp. ${NumberFormat(0, detailTransaction?.edc)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1">Grabfood</Typography>
                  <Typography variant="h5" color="primary">
                    {`Rp. ${NumberFormat(
                      0,
                      detailTransaction?.grabPaymentTottal
                    )}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body1">Expenses</Typography>
                  <Typography variant="h5" color="primary">
                    {`Rp. ${NumberFormat(
                      0,
                      detailTransaction?.exspanseTottal
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
              {detailTransaction?.data && (
                <>
                  <Typography variant="h6" sx={{ mt: 4 }}>
                    Product List
                  </Typography>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">No</TableCell>
                          <TableCell>Product Code</TableCell>
                          <TableCell>Product Variant</TableCell>
                          <TableCell align="center">Quantity</TableCell>
                          <TableCell align="center">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailTransaction.data.map((itemData, index) => (
                          <TableRow key={itemData.id}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell>{itemData.productCode}</TableCell>
                            <TableCell>{itemData.variantName}</TableCell>
                            <TableCell align="center">
                              {`${NumberFormat(0, itemData.quantity)} pcs`}
                            </TableCell>
                            <TableCell align="center">
                              {`Rp. ${NumberFormat(0, itemData.totalTrx)}`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      );
    } else {
      return (
        <>
          <DialogTitle sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Detail Transaction</span>
              {isDownloadPdf ? (
                <PDFDownloadLink
                  document={
                    <TransactionPDF
                      transactionSelected={detailTransaction}
                      detailTransaction={detailTransaction}
                    />
                  }
                  fileName="transaction-detail.pdf"
                  style={{ textDecoration: "none" }}
                >
                  {({ loading }) =>
                    loading ? (
                      <Button disabled>Loading Document...</Button>
                    ) : (
                      <Button variant="contained" color="primary">
                        Export PDF
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              ) : null}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              <Grid container spacing={1}>
                {isAmount ? (
                  <Grid
                    item
                    xs={12}
                    sm={detailTransaction?.statusName === "Done" ? 12 : 8}
                  >
                    <Typography variant="subtitle1">Amount</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5" color="primary">
                        {`Rp. ${NumberFormat(
                          0,
                          detailTransaction?.netPayment
                        )}`}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        sx={{
                          backgroundColor: `${
                            detailTransaction?.statusName === "Done"
                              ? "#daffda"
                              : "red"
                          }`,
                          borderRadius: 8,
                        }}
                      >
                        <Typography
                          variant="caption"
                          color={
                            detailTransaction?.statusName === "Done"
                              ? "#007800"
                              : "white"
                          }
                        >
                          {detailTransaction?.statusName}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ) : null}

                {isProductReceive ? (
                  <>
                    {detailTransaction?.statusName === "In Progres" ? (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                          }}
                        >
                          <Button
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                            variant="outlined"
                            onClick={onCancel}
                            startIcon={<CancelIcon />}
                            color="error"
                          >
                            Cancel Order
                          </Button>
                          <Button
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                            color="success"
                            variant="contained"
                            onClick={onHandleFinishOrder}
                            startIcon={<CheckCircleIcon />}
                          >
                            Finish Order
                          </Button>
                        </Box>
                      </Grid>
                    ) : null}
                  </>
                ) : null}

                <Grid item xs={12} sm={4} sx={{ marginTop: 2 }}>
                  <Typography variant="subtitle1">Transaction No:</Typography>
                  <Typography variant="body2">
                    {detailTransaction?.transactionNo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: 2 }}>
                  <Typography variant="subtitle1">Transaction Date:</Typography>
                  <Typography variant="body2">
                    {moment(detailTransaction?.transactionDate).format(
                      "DD MMM YYYY, h:mm"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: 2 }}>
                  <Typography variant="subtitle1">Transaction Type:</Typography>
                  <Typography variant="body2">
                    {detailTransaction?.transactionType}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ mt: 4 }}>
                Transaction Details
              </Typography>
              <Divider
                sx={{
                  paddingTop: "5px",
                }}
              />
              <Grid sx={{ padding: "10px" }} container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Transaction From</Typography>
                  <Typography variant="body2">
                    {detailTransaction?.transactionFromName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Transaction To</Typography>
                  <Typography variant="body2">
                    {detailTransaction?.transactionToName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Items</Typography>
                  <Typography variant="body2">
                    {`${NumberFormat(0, detailTransaction?.item)} Items`}
                  </Typography>
                </Grid>
              </Grid>
              {detailTransaction?.attachments && (
                <>
                  <Typography variant="h6" sx={{ mt: 4 }}>
                    Items
                  </Typography>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Product Variant</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailTransaction.attachments.map(
                          (itemData, index) => (
                            <TableRow key={itemData.id}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{itemData.productName}</TableCell>
                              <TableCell>{itemData.variantName}</TableCell>
                              <TableCell>{itemData.categoryName}</TableCell>
                              <TableCell>
                                {`${NumberFormat(0, itemData.quantity)} pcs`}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </>
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen ? true : false}
      scroll={scroll}
      fullWidth
      maxWidth="lg"
      open={isVisibleDetail}
    >
      {renderDetail()}
    </Dialog>
  );
}

export default ModalDetail;
