import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import {
  tableHeadStockOpname,
  tableHeadAddStockOpname,
} from "../../utils/Constants";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import moment from "moment";
import NumberFormat from "../../helpers/functions/numberFormat";
import positiveNumFormat from "../../helpers/functions/positiveNumFormat";
import ModalDetail from "../../components/ModalDetail";

function StockOpname() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageStokOpname, setPageStockOpname] = useState(0);
  const [rowsPerPageStockOpname, setRowsPerPageStockOpname] = useState(10);
  const [pageDetailSO, setPageDetailSO] = useState(0);
  const [rowsPerPageDetailSO, setRowsPerPageDetailSO] = useState(10);
  const [totalRecordDetailSO, setTotalRecordDetailSO] = useState(0);
  // ==================
  const [stockOpnameList, setStockOpnameList] = useState([]);
  const [stockOpnameDetailSelected, setStockOpnameDetailSelected] = useState(
    []
  );
  const [grandTotalStockOpname, setGrandTotalStockOpname] = useState(0);
  // ==================
  const [branchListTable, setBranchListTable] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  // ==================
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [searchVariantName, setSearchVariantName] = useState("");
  // ==================
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [notes, setNotes] = useState("");
  const [stockOpnameProductList, setStockOpnameProductList] = useState([]);
  const [detailStockOpname, setDetailStockOpname] = useState([]);
  const [dateStockOpname, setDateStockOpname] = useState(null);

  // ==================
  // ==================

  const onGetStockOpnameList = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api
        .getStockOpnameList(currentPage)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setStockOpnameList(res.data.data);
            setTotalRecord(res.data.totalPage * res.data.limit);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const newBranch = {
              id: "",
              branchName: "All Branches",
              address: "",
              branchPhone: "",
              statusName: "",
              statusCode: "",
              branchCode: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedBranchList = [newBranch, ...res.data.data];
            setBranchListTable(updatedBranchList);
            setBranchList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetInventory = async () => {
    setIsSpinner(true);
    try {
      const res = await api
        .getStockOpnameProductList(branchNameSelected.branchCode)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const stockOpnameProducts = res.data.data;
            const newDataProductOpname = stockOpnameProducts.map((i, x) => {
              return {
                ...i,
                inventoryQuantity: i.quantity,
                stockOpnameQuantity: i.quantity,
                totalProductDiffrence: 0,
                stockOpnameType: "",
                stockOpnameTypeCode: "",
                stockOpnamePrice: 0,
              };
            });
            setStockOpnameProductList(newDataProductOpname);
            setIsSpinner(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetDetailStockOpname = async (data, currentPageDetailSO) => {
    const newCurrentPageDetailSO = currentPageDetailSO + 1;
    try {
      const res = await api
        .getDetailStockOpname(data.id, newCurrentPageDetailSO)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const convertStockOpnameMinus = `-${data.TotalMinusPrice}`;
            const grandTotalStockOpname =
              parseInt(convertStockOpnameMinus) + parseInt(data.TotalPlusPrice);
            const arrayItem = res.data.data;
            const attachments = arrayItem.map((item) => {
              if (item.stockOpnameTypeCode === "63") {
                return {
                  ...item,
                  stockOpnamePrice: `-${item.stockOpnamePrice}`,
                };
              } else if (item.stockOpnameTypeCode === "62") {
                return {
                  ...item,
                  stockOpnamePrice: `${item.stockOpnamePrice}`,
                };
              }
              return item;
            });
            const newData = {
              ...data,
              attachments,
            };
            console.log("newData", newData);
            setTotalRecordDetailSO(res.data.totalPage * res.data.limit);
            setDetailStockOpname(newData);
            setGrandTotalStockOpname(grandTotalStockOpname);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onSaveStockOpname = async (
    totalTrxPrice,
    negativeDiffSummary,
    positiveDiffSummary,
    totalStockOpnameQuantity,
    totalStockOpnamePrice,
    totalnventoryQuantity,
    totalTrxtInvetory,
    updatedProductList
  ) => {
    setIsOpenModal(false);
    setIsSpinner(true);
    const bodyStockOp = {
      transactionDate: dateStockOpname,
      branchCode: branchNameSelected.branchCode,
      branchName: branchNameSelected.branchName,
      totalTrxPrice: totalTrxPrice,
      TotalMinusPrice: negativeDiffSummary.totalMinusPrice,
      TotalPlusPrice: positiveDiffSummary.totalPlusPrice,
      totalMinusQuantity: negativeDiffSummary.totalMinusQuantity,
      totalPlusQuantity: positiveDiffSummary.totalPlusQuantity,
      totalStockOpnameQuantity: totalStockOpnameQuantity,
      TotalStockOpnamePrice: totalStockOpnamePrice,
      totalnventoryQuantity: totalnventoryQuantity,
      totalTrxtInvetory: totalTrxtInvetory,
      attachments: updatedProductList,
    };
    console.log("body save stock opname", bodyStockOp);
    try {
      const res = await api
        .onSaveStockOpname(
          dateStockOpname,
          branchNameSelected,
          totalTrxPrice,
          negativeDiffSummary,
          positiveDiffSummary,
          totalStockOpnameQuantity,
          totalStockOpnamePrice,
          totalnventoryQuantity,
          totalTrxtInvetory,
          updatedProductList
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ===================
  useEffect(() => {
    onGetStockOpnameList();
  }, [page]);

  useEffect(() => {
    if (isReload) {
      onGetStockOpnameList();
    }
  }, [isReload]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    if (branchNameSelected.length != 0) {
      onGetInventory();
    }
  }, [branchNameSelected]);

  // ================

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePageDetailSO = (event, newPage) => {
    setPageDetailSO(newPage);
    onGetDetailStockOpname(stockOpnameDetailSelected, newPage);
  };

  const handleChangeRowsPerPageDetailSO = (event) => {
    setRowsPerPageDetailSO(+event.target.value);
    setPageDetailSO(0);
    onGetDetailStockOpname(stockOpnameDetailSelected, pageDetailSO);
  };

  const onCloseModal = async () => {
    setDateStockOpname(null);
    setPageStockOpname(0);
    setPageDetailSO(0);
    setTotalRecordDetailSO(0);
    setBranchNameSelected([]);
    setSearchBranchName("");
    setNotes("");
    setStockOpnameProductList([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setMode("");
    setErrors({});
    setIsOpenModal(false);
  };

  // master
  // const handleInputQuantityChange = (event, index) => {
  //   const newProducts = [...stockOpnameProductList];
  //   newProducts[index][event.target.name] = event.target.value;
  //   newProducts[index].updatedQuantity = event.target.value;
  //   setStockOpnameProductList(newProducts);
  // };

  // const calculateTotalDiffrence = (index) => {
  //   const newProducts = [...stockOpnameProductList];
  //   newProducts[index].totalProductDiffrence =
  //     newProducts[index].stockOpnameQuantity - newProducts[index].quantity;
  //   newProducts[index].stockOpnamePrice =
  //     newProducts[index].trxPrice * newProducts[index].totalProductDiffrence;
  //   newProducts[index].stockOpnameType =
  //     newProducts[index].totalProductDiffrence > 0
  //       ? "STOCK OPNAME PLUS"
  //       : "STOCK OPNAME MINUS";
  //   newProducts[index].stockOpnameTypeCode =
  //     newProducts[index].totalProductDiffrence > 0 ? 62 : 63;
  //   setStockOpnameProductList(newProducts);
  // };

  const handleInputQuantityChange = (event, productCode) => {
    const productIndex = stockOpnameProductList.findIndex(
      (product) => product.productCode === productCode
    );

    if (productIndex !== -1) {
      const newProducts = [...stockOpnameProductList];
      newProducts[productIndex][event.target.name] = event.target.value;
      newProducts[productIndex].updatedQuantity = event.target.value;
      setStockOpnameProductList(newProducts);
    } else {
      console.warn(
        `Product with code "${productCode}" not found in stockOpnameProductList.`
      );
    }
  };

  const calculateTotalDiffrence = (productCode) => {
    const newProducts = [...stockOpnameProductList];
    const productIndex = newProducts.findIndex(
      (product) => product.productCode === productCode
    );

    if (productIndex !== -1) {
      newProducts[productIndex].totalProductDiffrence =
        newProducts[productIndex].stockOpnameQuantity -
        newProducts[productIndex].quantity;
      newProducts[productIndex].stockOpnamePrice =
        newProducts[productIndex].trxPrice *
        newProducts[productIndex].totalProductDiffrence;
      newProducts[productIndex].stockOpnameType =
        newProducts[productIndex].totalProductDiffrence > 0
          ? "STOCK OPNAME PLUS"
          : "STOCK OPNAME MINUS";
      newProducts[productIndex].stockOpnameTypeCode =
        newProducts[productIndex].totalProductDiffrence > 0 ? 62 : 63;
      setStockOpnameProductList(newProducts);
    } else {
      console.warn(
        `Product with code "${productCode}" not found in stockOpnameProductList.`
      );
    }
  };

  const totalPriceOpname = () => {
    const totalPrice = stockOpnameProductList.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.stockOpnamePrice,
      0
    );

    return totalPrice;
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    setStockOpnameDetailSelected(itemSelected);
    onGetDetailStockOpname(itemSelected, pageDetailSO);
  };

  const validateFormStockOpname = () => {
    if (stockOpnameProductList.length === 0) {
      onError("Branch / Outlet is required to choose...");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateFormStockOpname();
    if (isValid) {
      const newArrayStockOpname = await stockOpnameProductList.filter(
        (item) => item.totalProductDiffrence != 0
      );

      const updatedProductList = newArrayStockOpname.map((product) => {
        const updatedProduct = { ...product };
        updatedProduct.totalProductDiffrence = positiveNumFormat(
          product.totalProductDiffrence
        );
        updatedProduct.stockOpnamePrice = positiveNumFormat(
          product.stockOpnamePrice
        );
        // delete updatedProduct.productCode;
        return updatedProduct;
      });

      const negativeDiffData = await stockOpnameProductList.filter(
        (item) => item.totalProductDiffrence < 0
      );

      const positiveDiffData = await stockOpnameProductList.filter(
        (item) => item.totalProductDiffrence > 0
      );

      const totalTrxPrice = await stockOpnameProductList.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.trxPrice),
        0
      );

      const negativeDiffSummary = await negativeDiffData.reduce(
        (accumulator, currentValue) => {
          return {
            totalMinusPrice:
              accumulator.totalMinusPrice +
              positiveNumFormat(currentValue.totalProductDiffrence) *
                currentValue.trxPrice,
            totalMinusQuantity:
              accumulator.totalMinusQuantity +
              positiveNumFormat(currentValue.totalProductDiffrence),
          };
        },
        { totalMinusPrice: 0, totalMinusQuantity: 0 }
      );

      const positiveDiffSummary = await positiveDiffData.reduce(
        (accumulator, currentValue) => {
          return {
            totalPlusPrice:
              accumulator.totalPlusPrice +
              currentValue.totalProductDiffrence * currentValue.trxPrice,
            totalPlusQuantity:
              accumulator.totalPlusQuantity +
              parseInt(currentValue.totalProductDiffrence),
          };
        },
        { totalPlusPrice: 0, totalPlusQuantity: 0 }
      );

      const totalStockOpnameQuantity = await stockOpnameProductList.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.stockOpnameQuantity),
        0
      );

      const totalStockOpnamePrice = await stockOpnameProductList.reduce(
        (accumulator, currentValue) =>
          accumulator + positiveNumFormat(currentValue.stockOpnamePrice),
        0
      );

      const totalnventoryQuantity = await stockOpnameProductList.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.quantity),
        0
      );

      const totalTrxtInvetory = await stockOpnameProductList.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.trxPrice),
        0
      );

      onSaveStockOpname(
        totalTrxPrice,
        negativeDiffSummary,
        positiveDiffSummary,
        totalStockOpnameQuantity,
        totalStockOpnamePrice,
        totalnventoryQuantity,
        totalTrxtInvetory,
        updatedProductList
      );
    }
  };

  const onSuccessSubmit = async () => {
    setDateStockOpname(null);
    setBranchNameSelected([]);
    setSearchBranchName("");
    setNotes("");
    setStockOpnameProductList([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setIsLoading(false);
    setIsOpen(true);
    setIsOpenModal(false);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Stock Opname
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Stock Opname</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Stock Opname List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add Stock Opname
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetStockOpnameList();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="stockOpname-list"
          tableName={"Stock Opname List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadStockOpname}
          data={stockOpnameList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
        />
      </div>

      <Modal
        fullScreen={true}
        modalTitle={"Add Stock Opname"}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Stock Opname"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ marginBottom: "5px", marginTop: "15px" }}
            >
              <PickerSearch
                value={branchNameSelected}
                dataList={branchList}
                onInputChange={(event, newInputValue) => {
                  setSearchBranchName(newInputValue);
                }}
                onChange={(e, v) => {
                  if (v) {
                    setBranchNameSelected(v);
                    setStockOpnameProductList([]);
                  } else {
                    setBranchNameSelected("");
                    setStockOpnameProductList([]);
                  }
                }}
                getOptionLabel={(branchList) => branchList.branchName || ""}
                labelPicker={"Choose Branch / Outlet"}
                placeholderPicker={"search branch / outlet"}
                sizePicker={"large"}
                isVisibleLabelPicker={true}
                labelTitlePicker={""}
                error={!!errors.branchNameSelected}
                helperText={errors.branchNameSelected}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormDatePicker
                labelFormDatePicker="Date Stock Opname"
                formSize={"large"}
                dateValue={dateStockOpname}
                onChangeDate={(val) => setDateStockOpname(val)}
                sx={{ marginTop: 2 }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormInput
                label="Notes"
                name="notes"
                error={!!errors.notes}
                helperText={errors.notes}
                value={notes}
                onChange={(event) => {
                  setNotes(event.target.value);
                }}
                multiline
                rows={4}
                maxRows={4}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer mt={1}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeadAddStockOpname.map((item) => {
                      return (
                        <TableCell
                          // colSpan={item.size}
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#060270",
                            color: "white",
                            width: item.size,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockOpnameProductList.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableHeadAddStockOpname.length}
                        align="center"
                        sx={{ py: 4 }}
                      >
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              {` No product found. You can select outlet using the
                      button above.`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {(rowsPerPageStockOpname > 0
                        ? stockOpnameProductList.slice(
                            pageStokOpname * rowsPerPageStockOpname,
                            pageStokOpname * rowsPerPageStockOpname +
                              rowsPerPageStockOpname
                          )
                        : stockOpnameProductList
                      ).map((item, index) => (
                        <TableRow key={item.productCode}>
                          <TableCell>{`${item.productName} - ${item.variantName}`}</TableCell>
                          <TableCell>
                            {NumberFormat(0, item.quantity)}
                          </TableCell>
                          <TableCell>
                            {`Rp. ${NumberFormat(0, item.trxPrice)}`}
                          </TableCell>
                          <TableCell>
                            <FormInput
                              size="small"
                              label="Total Product Actual"
                              name="stockOpnameQuantity"
                              type="number"
                              error={!!errors.stockOpnameQuantity}
                              helperText={errors.stockOpnameQuantity}
                              value={parseInt(item.stockOpnameQuantity)}
                              onChange={(event) => {
                                handleInputQuantityChange(
                                  event,
                                  item.productCode
                                );
                                calculateTotalDiffrence(item.productCode);
                              }}
                              // onChange={(event) => {
                              //   handleInputQuantityChange(event, index);
                              //   calculateTotalDiffrence(index);
                              // }}
                            />
                          </TableCell>
                          <TableCell>
                            {NumberFormat(0, item.totalProductDiffrence)}
                          </TableCell>
                          <TableCell>
                            {`Rp. ${NumberFormat(0, item.stockOpnamePrice)}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                  <TableRow sx={{ "& > td": { borderBottom: "none" } }}>
                    <TableCell colSpan={4} />
                    <TableCell rowSpan={4}>
                      <Typography sx={{ fontSize: "18px" }}>Total</Typography>
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "18px" }}
                      align="left"
                    >
                      {stockOpnameProductList.length !== 0
                        ? NumberFormat(0, totalPriceOpname())
                        : "Rp. 0"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={stockOpnameProductList?.length}
              rowsPerPage={rowsPerPageStockOpname}
              page={pageStokOpname}
              onPageChange={(event, newPage) => {
                setPageStockOpname(newPage);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPageStockOpname(+event.target.value);
                setPageStockOpname(0);
              }}
            />
          </Grid>
        </Grid>
      </Modal>

      <ModalDetail
        type={"stock-opname"}
        fullScreen={true}
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        detailTransaction={detailStockOpname}
        grandTotal={NumberFormat(0, grandTotalStockOpname)}
        totalRecordDetailSO={totalRecordDetailSO}
        rowsPerPageDetailSO={rowsPerPageDetailSO}
        pageDetailSO={pageDetailSO}
        handleChangePageDetailSO={handleChangePageDetailSO}
        handleChangeRowsPerPageDetailSO={handleChangeRowsPerPageDetailSO}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
}

export default StockOpname;
