import React, { useState, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  Breadcrumbs,
  Link,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import {
  IoMenuOutline,
  IoHomeOutline,
  IoCartOutline,
  IoPersonOutline,
  IoStorefrontOutline,
  IoBarChartOutline,
  IoSettingsOutline,
  IoReceiptOutline,
  IoCubeOutline,
  IoWalletOutline,
  IoLogOutOutline,
  IoChevronForward,
  IoNotificationsOutline,
  IoChevronDownOutline,
  IoChevronForwardOutline,
} from "react-icons/io5";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

import AllInboxRoundedIcon from "@mui/icons-material/AllInboxRounded";
import ManageHistoryRoundedIcon from "@mui/icons-material/ManageHistoryRounded";
import PollRoundedIcon from "@mui/icons-material/PollRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import LiquorRoundedIcon from "@mui/icons-material/LiquorRounded";
import LocalConvenienceStoreRoundedIcon from "@mui/icons-material/LocalConvenienceStoreRounded";

import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import Groups3RoundedIcon from "@mui/icons-material/Groups3Rounded";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import TableViewIcon from "@mui/icons-material/TableView";

import { ThemeContext } from "../../contexts/ThemeContext";
import AuthContext from "../../contexts/AuthProvider";
// import { getRole } from "../../utils/auth";

// Sidebar width
const DRAWER_WIDTH = 280;

// Navigation items configuration
const mainNavItems = [
  { title: "Dashboard", path: "/", icon: <IoHomeOutline size={22} /> },
];

const reportNavItems = [
  {
    title: "Balance",
    path: "/balance",
    icon: <TableViewIcon />,
  },
  { title: "Sales", path: "/transaction", icon: <BarChartOutlinedIcon /> },
  { title: "Daily Report", path: "/dailyProvit", icon: <TrendingUpIcon /> },
  { title: "Product Profit", path: "/productProvit", icon: <LocalMallIcon /> },
  { title: "Current Stock", path: "/currentStock", icon: <MoveToInboxIcon /> },
];

const inventoryNavItems = [
  { title: "Stock Card", path: "/stockCard", icon: <WarehouseIcon /> },
  {
    title: "All Products",
    path: "/products",
    icon: <LiquorRoundedIcon />,
  },
  {
    title: "Purchase Order",
    path: "/purchases",
    icon: <ShoppingBasketRoundedIcon />,
  },
  {
    title: "Product Receipt",
    path: "/productReceipt",
    icon: <ReceiptIcon />,
  },
  { title: "Stock In", path: "/stockIn", icon: <MoveDownIcon /> },
  { title: "Stock Out", path: "/stockOut", icon: <MoveUpIcon /> },
  {
    title: "Transfer Stock",
    path: "/inventory",
    icon: <SwapHorizIcon />,
  },
  {
    title: "Stock Opname",
    path: "/stockOpname",
    icon: <ManageHistoryIcon />,
  },
];

const managementNavItems = [
  {
    title: "Categories",
    path: "/categories",
    icon: <ClassOutlinedIcon />,
  },
  {
    title: "Suppliers",
    path: "/supplier",
    icon: <LocalShippingRoundedIcon />,
  },
  {
    title: "Outlets",
    path: "/branches",
    icon: <LocalConvenienceStoreRoundedIcon />,
  },
  { title: "Users", path: "/users", icon: <Groups3RoundedIcon /> },
];

const MenuSection = ({
  title,
  items,
  location,
  navigate,
  isMobile,
  setMobileOpen,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Box
        onClick={handleToggle}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          py: 1,
          pl: 2,
          "&:hover": {
            bgcolor: "action.hover",
            borderRadius: 1,
          },
        }}
      >
        {isOpen ? (
          <IoChevronDownOutline size={16} style={{ marginRight: "8px" }} />
        ) : (
          <IoChevronForwardOutline size={16} style={{ marginRight: "8px" }} />
        )}
        <Typography
          variant="subtitle2"
          color="textSecondary"
          sx={{
            flex: 1,
            fontWeight: isOpen ? 600 : 400,
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* Collapsible Menu Items */}
      <Collapse in={isOpen} timeout="auto">
        <List>
          {items.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                selected={location.pathname === item.path}
                onClick={() => {
                  navigate(item.path);
                  if (isMobile) setMobileOpen(false);
                }}
                sx={{
                  borderRadius: 1,
                  mb: 0.5,
                  "&.Mui-selected": {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === item.path
                        ? "primary.main"
                        : "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const MainLayout = ({ navigationConfig }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { authData } = useContext(AuthContext);
  const userRole = authData.role;
  const { themeMode, setThemeMode } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Use navigationConfig instead of hardcoded arrays
  const {
    mainNavItems,
    reportNavItems,
    inventoryNavItems,
    managementNavItems,
  } = navigationConfig;

  // States
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Handlers
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // setAuthData(null);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("role");
    navigate("/login");
  };

  // Generate breadcrumbs
  const getBreadcrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    return (
      <Breadcrumbs separator={<IoChevronForward size={16} />} sx={{ my: 2 }}>
        <Link
          color="inherit"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Home
        </Link>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography key={name} color="textPrimary">
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </Typography>
          ) : (
            <Link
              key={name}
              color="inherit"
              href={routeTo}
              onClick={(e) => {
                e.preventDefault();
                navigate(routeTo);
              }}
            >
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
  };

  // Drawer content
  const drawer = (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* Logo */}
      <Box sx={{ p: 2, display: "flex", alignItems: "center", gap: 1 }}>
        <IoStorefrontOutline size={30} color={theme.palette.primary.main} />
        <Typography variant="h6" fontWeight="bold" color="primary">
          BOTTLE GARDEN
        </Typography>
      </Box>

      <Divider />

      {/* Navigation Lists */}
      <Box sx={{ flex: 1, p: 2, overflowY: "auto" }}>
        <List>
          {mainNavItems.map((item) => (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                selected={location.pathname === item.path}
                onClick={() => {
                  navigate(item.path);
                  if (isMobile) setMobileOpen(false);
                }}
                sx={{
                  borderRadius: 1,
                  mb: 0.5,
                  "&.Mui-selected": {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === item.path
                        ? "primary.main"
                        : "inherit",
                  }}
                >
                  {item.icon || <IoHomeOutline size={22} />}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {/* Report Sales Section */}
        <MenuSection
          title="Report Sales"
          items={reportNavItems}
          location={location}
          navigate={navigate}
          isMobile={isMobile}
          setMobileOpen={setMobileOpen}
        />

        {/* Inventory products Section */}
        <MenuSection
          title="Inventory Products"
          items={inventoryNavItems}
          location={location}
          navigate={navigate}
          isMobile={isMobile}
          setMobileOpen={setMobileOpen}
        />

        {/* Inventory Management Section */}
        {userRole === "admin" || userRole === "owner" ? (
          <MenuSection
            title="Management"
            items={managementNavItems}
            location={location}
            navigate={navigate}
            isMobile={isMobile}
            setMobileOpen={setMobileOpen}
          />
        ) : null}
        {/* <MenuSection
          title="Management"
          items={managementNavItems}
          location={location}
          navigate={navigate}
          isMobile={isMobile}
          setMobileOpen={setMobileOpen}
        /> */}
      </Box>

      {/* User Profile Section */}
      <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
        <ListItemButton
          onClick={handleProfileMenuOpen}
          sx={{ borderRadius: 1 }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: "primary.main",
              }}
            >
              A
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Admin User"
            secondary="admin@example.com"
            primaryTypographyProps={{ variant: "subtitle2" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </ListItemButton>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      {/* App Bar */}
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color={theme.palette.primary.main}
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <IoMenuOutline />
          </IconButton>

          {/* Header Actions */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Tooltip title={authData?.name || "Admin User"}>
              <IconButton
                onClick={handleProfileMenuOpen}
                size="large"
                color={theme.palette.primary.main}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: "primary.main",
                  }}
                >
                  {authData?.name?.charAt(0) || "A"}
                </Avatar>
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Settings">
              <IconButton size="large" color={theme.palette.primary.main}>
                <IoSettingsOutline />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Sidebar / Drawer */}
      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
      >
        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              bgcolor: "background.paper",
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Desktop Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              bgcolor: "background.paper",
              borderRight: 1,
              borderColor: "divider",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          height: "100vh",
          bgcolor: "background.default",
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <Box
          sx={{ height: "calc(100vh - 64px)", overflowY: "auto", padding: 2 }}
        >
          <Box sx={{ px: 3 }}>{getBreadcrumbs()}</Box>
          <Outlet />
        </Box>
      </Box>

      {/* Profile Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        onClick={handleProfileMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate("/profile")}>
          <ListItemIcon>
            <IoPersonOutline size={20} />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={() => navigate("/settings")}>
          <ListItemIcon>
            <IoSettingsOutline size={20} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <IoLogOutOutline size={20} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default MainLayout;

// Master
// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import { Outlet } from "react-router-dom";
// import { useTheme } from "@mui/material/styles";
// import SideBar from "./Sidebar";
// import Navbar from "./Navbar";

// function MainLayout({ children }) {
//   const theme = useTheme();
//   const [open, setOpen] = useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//       <div className="app">
//         <SideBar />
//         <main className="content">
//           <Navbar />
//           <div className="main-bg overflow-auto max-h-screen pb-24">
//             <Outlet />
//           </div>
//         </main>
//       </div>
//     </>
//   );
// }

// export default MainLayout;
