export const tableHeadDashboard = [
  { id: "no", name: "No", tableAlign: "left" },
  { id: "product", name: "PRODUCT NAME", tableAlign: "left" },
  { id: "transactionPrice", name: "TRANSACTION", tableAlign: "left" },
  { id: "basePrice", name: "BASE PRICE", tableAlign: "left" },
  { id: "netPrice", name: "NET PRICE", tableAlign: "left" },
  { id: "grossProvit", name: "% GROSS PROVIT", tableAlign: "left" },
];

export const tableHeadProductProvit = [
  { id: "no", name: "No", tableAlign: "left" },
  { id: "productName", name: "PRODUCT NAME", tableAlign: "left" },
  { id: "totalquantity", name: "TOTAL QUANTITY", tableAlign: "left" },
  { id: "netPrice", name: "NET PRICE", tableAlign: "left" },
];

export const tableHeadBalance = [
  { id: "outlet", name: "OUTLET NAME", tableAlign: "left" },
  { id: "totalIncome", name: "TOTAL INCOME", tableAlign: "left" },
  { id: "totalExpenses", name: "TOTAL EXPENSES", tableAlign: "left" },
  { id: "openDate", name: "START DATE", tableAlign: "left" },
  { id: "closeDate", name: "CLOSING DATE", tableAlign: "left" },
  { id: "action", name: "ACTION", tableAlign: "center" },
];

export const tableHeadIncome = [
  { id: "outlet", name: "OUTLET NAME", tableAlign: "left" },
  { id: "transactionNo", name: "TRANSACTION NO", tableAlign: "left" },
  { id: "itemsTransaction", name: "ITEM TRANSACTIONS", tableAlign: "left" },
  { id: "orderCost", name: "ORDER COST", tableAlign: "left" },
  { id: "transactionDate", name: "TRANSACTION DATE", tableAlign: "left" },
  { id: "action", name: "ACTION", tableAlign: "center" },
];

export const tableHeadExpenses = [
  { id: "branchName", name: "OUTLET NAME", tableAlign: "left" },
  { id: "transactionDate", name: "TRANSACTION DATE", tableAlign: "left" },
  { id: "transactionType", name: "TRANSACTION TYPE", tableAlign: "left" },
  { id: "note", name: "NOTE", tableAlign: "left" },
  { id: "total", name: "TOTAL", tableAlign: "left" },
  { id: "action", name: "ACTION", tableAlign: "center" },
];

export const tableHeadTransaction = [
  { id: "transactionNo", name: "TRANSACTION NO", tableAlign: "left" },
  { id: "branchName", name: "OUTLET NAME", tableAlign: "left" },
  { id: "itemsTransaction", name: "TRANSACTION ITEMS", tableAlign: "left" },
  { id: "orderCost", name: "ORDER COST", tableAlign: "left" },
  { id: "transactionDate", name: "TRANSACTION DATE", tableAlign: "left" },
  { id: "status", name: "STATUS", tableAlign: "left" },
  { id: "action", name: "ACTION", tableAlign: "center" },
];

export const tableHeadDailyProvit = [
  { id: "totalTransaction", name: "TOTAL TRANSACTION", tableAlign: "left" },
  { id: "totalSales", name: "TOTAL SALES", tableAlign: "left" },
  { id: "avgSales", name: "AVERAGE SALES", tableAlign: "left" },
  { id: "dateTrx", name: "TRANSACTION DATE", tableAlign: "left" },
];

// export const tableHeadCurrentStock = [
//   { id: "branchName", name: "NO", tableAlign: "left" },
//   { id: "branchName", name: "PRODUCT NAME", tableAlign: "left" },
//   { id: "itemsTransaction", name: "BASE STOCK", tableAlign: "left" },
//   { id: "itemsTransaction", name: "TOTAL IN", tableAlign: "left" },
//   { id: "itemsTransaction", name: "TOTAL OUT", tableAlign: "left" },
//   { id: "itemsTransaction", name: "TOTAL SELLING", tableAlign: "left" },
//   { id: "orderCost", name: "FINAL STOCK", tableAlign: "left" },
// ];

export const tableHeadCurrentStock = [
  { id: "no", name: "NO", tableAlign: "left" },
  { id: "productCode", name: "PRODUCT CODE", tableAlign: "left" },
  { id: "productName", name: "PRODUCT NAME", tableAlign: "left" },
  { id: "currentStock", name: "CURRENT STOCK", tableAlign: "left" },
];

export const tableHeadProduct = [
  { id: "no", name: "NO" },
  { id: "masterProductCode", name: "PRODUCT CODE" },
  { id: "ProductName", name: "PRODUCT NAME" },
  { id: "categoryProduct", name: "CATEGORY NAME" },
  { id: "action", name: "ACTION" },
];

export const tableHeadVariant = [
  { id: "no", name: "NO" },
  { id: "variantName", name: "VARIANT NAME" },
  { id: "productsku", name: "PRODUCT SKU" },
  { id: "basicPrice", name: "BASIC PRICE" },
  { id: "action", name: "" },
];

export const tableHeadVariantDetail = [
  { id: "no", name: "NO", tableAlign: "left" },
  { id: "variantName", name: "VARIANT NAME", tableAlign: "left" },
  { id: "productsku", name: "PRODUCT SKU", tableAlign: "left" },
  { id: "basicPrice", name: "BASIC PRICE", tableAlign: "left" },
];

export const tableHeadSellingPriceDetail = [
  { id: "no", name: "NO", tableAlign: "left" },
  { id: "variantName", name: "VARIANT NAME", tableAlign: "left" },
  { id: "productsku", name: "PRODUCT SKU", tableAlign: "left" },
  { id: "basicPrice", name: "BASIC PRICE", tableAlign: "left" },
  { id: "sellingPrice", name: "SELLING PRICE", tableAlign: "left" },
  { id: "isBottleMandatory", name: "BOTTLE STATUS", tableAlign: "left" },
];

export const tableHeadAddOn = [
  { id: "no", name: "NO" },
  { id: "addOnName", name: "ADD ON NAME" },
  { id: "addOnsType", name: "ADD ON TYPE" },
  { id: "branchCode", name: "BRANCH" },
  { id: "price", name: "PRICE" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];

export const tableHeadProductReceive = [
  { id: "transactionNo", name: "TRANSACTION NO" },
  { id: "transactionType", name: "TRANSACTION TYPE" },
  { id: "totalItemTransaction", name: "TOTAL TRANSACTION" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];

export const tableHeadStockIn = [
  { id: "branchLocation", name: "OUTLET NAME" },
  // { id: "productName", name: "PRODUCT NAME" },
  { id: "quantity", name: "QUANTITY" },
  { id: "dateSubmit", name: "DATE SUBMIT" },
  { id: "dateStockIn", name: "DATE STOCK IN" },
  { id: "action", name: "ACTION" },
];

export const tableHeadStockOut = [
  { id: "branchLocation", name: "OUTLET NAME" },
  // { id: "productName", name: "PRODUCT NAME" },
  { id: "quantity", name: "QUANTITY" },
  { id: "dateSubmit", name: "DATE SUBMIT" },
  { id: "dateStockIn", name: "DATE STOCK IN" },
  { id: "action", name: "ACTION" },
];

export const tableHeadStockOpname = [
  { id: "no", name: "NO" },
  { id: "outletName", name: "OUTLET NAME" },
  { id: "submitDate", name: "SUBMIT DATE" },
  { id: "action", name: "ACTION" },
];

export const tableHeadAddStockOpname = [
  { id: "productName", name: "PRODUCT NAME" },
  { id: "quantitySystem", name: "TOTAL PRODUCT(SYSTEM)" },
  { id: "price", name: "PRICE" },
  { id: "quantityActual", name: "TOTAL PRODUCT(ACTUAL)" },
  { id: "totalDiffrence", name: "QUANTITY DIFFERENCE" },
  { id: "totalDiffrence", name: "TOTAL SUMMARY" },
];

export const tableHeadAddStockIn = [
  { id: "no", name: "NO", size: "5%" },
  { id: "productName", name: "PRODUCT NAME", size: "25%" },
  { id: "productVariant", name: "PRODUCT VARIANT", size: "25%" },
  { id: "quantity", name: "QUANTITY", size: "10%" },
  { id: "price", name: "PRICE (unit)", size: "15%" },
  { id: "totalPrice", name: "TOTAL PRICE", size: "15%" },
  { id: "action", name: "ACTION", size: "5%" },
];

export const tableHeadAddStockOut = [
  { id: "no", name: "NO" },
  { id: "productName", name: "PRODUCT NAME" },
  { id: "quantity", name: "QUANTITY" },
  { id: "price", name: "PRICE (unit)" },
  { id: "totalPrice", name: "TOTAL PRICE" },
  { id: "action", name: "ACTION" },
];

export const tableHeadPurchases = [
  { id: "transactionNo", name: "TRANSACTION NO" },
  { id: "transactionType", name: "TRANSACTION TYPE" },
  { id: "totalItemTransaction", name: "TOTAL TRANSACTION" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];

export const tableHeadPO = [
  { id: "no", name: "NO", size: "5%" },
  { id: "productName", name: "PRODUCT NAME", size: "25%" },
  { id: "productVariant", name: "PRODUCT VARIANT", size: "25%" },
  { id: "quantity", name: "QUANTITY", size: "10%" },
  { id: "price", name: "PRICE (unit)", size: "15%" },
  { id: "totalPrice", name: "TOTAL PRICE", size: "15%" },
  { id: "action", name: "ACTION", size: "5%" },
];

export const tableHeadTransferList = [
  { id: "idTrxStock", name: "ID TRANSFER" },
  { id: "branchFrom", name: "FROM OUTLET" },
  { id: "branchTo", name: "TO OUTLET" },
  { id: "trxDate", name: "TRANSFER DATE" },
  { id: "action", name: "ACTION" },
];

export const tableHeadTransferProduct = [
  { id: "no", name: "NO", size: "10%" },
  { id: "productVariant", name: "PRODUCT VARIANT", size: "30%" },
  { id: "currentStock", name: "CURRENT STOCK", size: "20%" },
  { id: "deliveryStock", name: "DELIVERY STOCK", size: "30%" },
  { id: "action", name: "ACTION", size: "10%" },
];

export const tableHeadBrand = [
  { id: "no", name: "No" },
  { id: "brandName", name: "BRAND NAME" },
  { id: "createdAt", name: "CREATED" },
  { id: "createdAt", name: "UPDATED" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];

export const tableHeadCategory = [
  { id: "no", name: "NO" },
  { id: "categoryName", name: "CATEGORY NAME" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];

export const tableHeadStockCard = [
  { id: "productName", name: "PRODUCT NAME" },
  { id: "baseStock", name: "BASE STOCK" },
  { id: "stockIn", name: "STOCK IN" },
  { id: "stockOut", name: "STOCK OUT" },
  { id: "tfxIn", name: "TRANSFER IN" },
  { id: "trxOut", name: "TRANSFER OUT" },
  { id: "totalSelling", name: "TOTAL SELLING" },
  { id: "stockOpname", name: "STOCK OPNAME" },
  { id: "finalStock", name: "FINAL STOCK" },
];

export const tableHeadSupplier = [
  { id: "no", name: "NO" },
  { id: "supplierName", name: "SUPPLIER NAME" },
  { id: "SupplierContact", name: "SUPPLIER CONTACT" },
  { id: "supplierPhone", name: "PHONE NUMBER" },
  { id: "supplierEmail", name: "SUPPLIER EMAIL" },
  { id: "action", name: "ACTION" },
];

export const tableHeadBranch = [
  { id: "no", name: "NO" },
  { id: "branchName", name: "OUTLET NAME" },
  { id: "branchAddress", name: "ADDRESS" },
  { id: "branchPhone", name: "PHONE" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];

export const statusList = [
  {
    id: 1,
    label: "Active",
    value: 201,
  },
  {
    id: 2,
    label: "Inactive",
    value: 301,
  },
];

export const tableHeadUser = [
  { id: "no", name: "NO" },
  { id: "username", name: "USERNAME" },
  { id: "email", name: "EMAIL" },
  { id: "branch", name: "BRANCH" },
  { id: "status", name: "STATUS" },
  { id: "action", name: "ACTION" },
];
