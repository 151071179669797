import React, { useState, createContext } from "react";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material";

export const ThemeContext = createContext();

const getTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: "#f73378",
        dark: "#eb1865",
      },
      background: {
        default: mode === "light" ? "#f5f5f5" : "#121212",
        paper: mode === "light" ? "#ffffff" : "#1e1e1e",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor:
              mode === "light"
                ? "rgba(255, 255, 255, 0.9)"
                : "rgba(30, 30, 30, 0.9)",
            backdropFilter: "blur(10px)",
          },
        },
      },
    },
  });

export const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState("light");
  const theme = getTheme(themeMode);

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
