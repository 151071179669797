import React, { createContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Initialize authData from localStorage
  const [authData, setAuthData] = useState({
    token: localStorage.getItem("accessToken"),
    role: localStorage.getItem("role"),
  });

  // Update localStorage whenever authData changes
  useEffect(() => {
    if (authData.token) {
      localStorage.setItem("accessToken", authData.token);
    } else {
      localStorage.removeItem("accessToken");
    }

    if (authData.role) {
      localStorage.setItem("role", authData.role);
    } else {
      localStorage.removeItem("role");
    }
  }, [authData]);

  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
