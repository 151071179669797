import React from "react";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./contexts/AuthProvider";
import { ThemeProvider } from "./contexts/ThemeContext";
import { Routers } from "./routes";

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <CssBaseline />
        <Routers />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
