import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  Divider,
  Switch,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import {
  IoRocketOutline,
  IoMailOutline,
  IoEyeOutline,
  IoEyeOffOutline,
} from "react-icons/io5";
import { ThemeContext } from "../../contexts/ThemeContext";
import LoadingButton from "@mui/lab/LoadingButton";
import AuthContext from "../../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import api from "../../helpers/api";
import { generateCorrelationId } from "../../helpers/functions/generateRandomChar";
import CustomizedSnackbars from "../../components/base/Snackbar";

const Login = () => {
  const theme = useTheme();
  const { themeMode, setThemeMode } = useContext(ThemeContext);
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();

  // Snackbar dan loading state
  //   // const [username, setUsername] = useState("akbar@admin.com");
  //   // const [password, setPassword] = useState("123456");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  // ==============
  const onHandleLogin = async () => {
    try {
      const correlationId = generateCorrelationId();
      setIsLoading(true);
      const res = await api
        .onLogin(formData.username, formData.password, {
          headers: { "X-Correlation-Id": correlationId },
        })
        .then(async (res) => {
          if (res.statusCode === 200) {
            const data = res.data;
            onSuccessSubmit(data);
            setIsLoading(false);
          } else {
            onErrorSubmit(res.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          onErrorSubmit(error.message);
          setErrors({ general: "An error occurred. Please try again." });
          setIsLoading(false);
        });
    } catch (error) {
      onErrorSubmit(error.message);
      setErrors({ general: "An error occurred. Please try again." });
      setIsLoading(false);
    }
  };

  // ==============
  const validateEmail = (username) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(username);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};
    let isValid = true;

    if (!formData.username) {
      newErrors.username = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.username)) {
      newErrors.username = "Please enter a valid email";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (!validatePassword(formData.password)) {
      newErrors.password = "Password must be at least 6 characters";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      onHandleLogin();
    }
  };

  const onSuccessSubmit = async (data) => {
    const role = formData.username === "akbar@admin.com" ? "admin" : "user";
    const accessToken = data.payload;

    setAuthData({ token: accessToken, role });
    localStorage.setItem("isLoggedIn", true);

    setIsLoading(false);
    navigate("/");

    // const accessToken = data.payload;
    // const users = data.data;
    // setAuthData({ data });
    // localStorage.setItem(
    //   "role",
    //   formData.username == "akbar@admin.com" ? "admin" : "user"
    // );
    // // localStorage.setItem("role", userInfo.role);
    // localStorage.setItem("isLoggedIn", true);
    // setIsLoading(false);
    // navigate("/");
  };

  const onErrorSubmit = async (message) => {
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  // =================
  const getBackgroundStyle = (mode) => ({
    background:
      mode === "light"
        ? `
          linear-gradient(120deg, rgba(247, 51, 120, 0.1), rgba(255, 255, 255, 0.1)),
          radial-gradient(circle at top right, rgba(247, 51, 120, 0.15), transparent 400px),
          radial-gradient(circle at bottom left, rgba(130, 71, 229, 0.15), transparent 400px)
        `
        : `
          linear-gradient(120deg, rgba(247, 51, 120, 0.05), rgba(18, 18, 18, 0.1)),
          radial-gradient(circle at top right, rgba(247, 51, 120, 0.1), transparent 400px),
          radial-gradient(circle at bottom left, rgba(130, 71, 229, 0.1), transparent 400px)
        `,
    backgroundSize: "100% 100%, 100% 100%, 100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  });

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.3s ease-in-out",
          ...getBackgroundStyle(themeMode),
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "-50%",
            left: "-50%",
            width: "200%",
            height: "200%",
            backgroundColor:
              themeMode === "light"
                ? "rgba(255,255,255,0.05)"
                : "rgba(0,0,0,0.05)",
            backgroundImage: `repeating-linear-gradient(
          0deg,
          transparent,
          transparent 20px,
          ${
            themeMode === "light"
              ? "rgba(255,255,255,0.1)"
              : "rgba(255,255,255,0.02)"
          } 20px,
          ${
            themeMode === "light"
              ? "rgba(255,255,255,0.1)"
              : "rgba(255,255,255,0.02)"
          } 40px
        )`,
            transform: "rotate(30deg)",
            zIndex: 0,
          },
        }}
      >
        <Container maxWidth="xs" sx={{ position: "relative", zIndex: 1 }}>
          {/* Theme Toggle */}
          {/* <Box
            sx={{
              position: "fixed",
              top: 16,
              right: 16,
              backgroundColor: "background.paper",
              borderRadius: 2,
              padding: "4px 8px",
              boxShadow: 1,
              zIndex: 1000,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={themeMode === "dark"}
                  onChange={() =>
                    setThemeMode(themeMode === "light" ? "dark" : "light")
                  }
                  color="primary"
                />
              }
              label={themeMode === "dark" ? "Dark Mode" : "Light Mode"}
            />
          </Box> */}

          <Paper
            elevation={4}
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 3,
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "4px",
                background: "linear-gradient(90deg, #f73378, #8247e5)",
              },
            }}
          >
            <Box sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}>
              <IoRocketOutline size={24} color={theme.palette.primary.main} />
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                BOTTLE GARDEN
              </Typography>
            </Box>

            <Typography
              component="h2"
              variant="h5"
              sx={{ mb: 3, fontWeight: 500 }}
            >
              Sign In
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ width: "100%" }}
            >
              <TextField
                fullWidth
                margin="normal"
                name="username"
                label="Email"
                type="email"
                value={formData.username}
                onChange={handleChange}
                error={Boolean(errors.username)}
                helperText={errors.username}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoMailOutline size={20} />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <IoEyeOffOutline size={20} />
                        ) : (
                          <IoEyeOutline size={20} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                fullWidth
                variant="contained"
                type="submit"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                }}
                loading={isLoading}
                loadingPosition="end"
              >
                <span>Sign In</span>
              </LoadingButton>

              <Box
                sx={{ my: 2, display: "flex", alignItems: "center", gap: 1 }}
              >
                <Divider sx={{ flex: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  {"Copyright © "}
                  <span>Bottle Garden</span> {new Date().getFullYear()}
                  {". "}
                </Typography>
                <Divider sx={{ flex: 1 }} />
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </>
  );
};

export default Login;

// Master
// import React, { useState, useContext } from "react";
// import AuthContext from "../../contexts/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import Avatar from "@mui/material/Avatar";
// import LoadingButton from "@mui/lab/LoadingButton";
// import Paper from "@mui/material/Paper";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Typography from "@mui/material/Typography";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { validateFormInput } from "../../helpers/functions/validateTextInput";
// import { FormInput } from "../../components";
// import api from "../../helpers/api";
// import { generateCorrelationId } from "../../helpers/functions/generateRandomChar";
// import CustomizedSnackbars from "../../components/base/Snackbar";

// const defaultTheme = createTheme();

// export default function Login() {
//   const { setAuthData } = useContext(AuthContext);
//   const navigate = useNavigate();

//   const [isLoading, setIsLoading] = useState(false);
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   // const [username, setUsername] = useState("akbar@admin.com");
//   // const [password, setPassword] = useState("123456");
//   const [errors, setErrors] = useState({});
//   const [isOpen, setIsOpen] = useState(false);
//   const [alertType, setAlertType] = useState("");
//   const [message, setMessage] = useState("");

//   // ============

//   const handleUsernameChange = (event) => {
//     setUsername(event.target.value);
//     setErrors({});
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//     setErrors({});
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     if (!username || username.trim() === "") {
//       newErrors.username = "Username is required";
//     } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
//       newErrors.username = "Invalid username format";
//     }

//     if (!password || password.trim() === "") {
//       newErrors.password = "Password is required";
//     } else if (password.length < 6) {
//       newErrors.password = "Password must be at least 6 characters";
//     }

//     return newErrors;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const validationErrors = validateForm();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     try {
//       await validateFormInput(username, password);
//       const correlationId = generateCorrelationId();

//       setIsLoading(true);
//       const res = await api
//         .onLogin(username, password, {
//           headers: { "X-Correlation-Id": correlationId },
//         })
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             const data = res.data;
//             onSuccessSubmit(data);
//             setIsLoading(false);
//           } else {
//             onErrorSubmit(res.message);
//             setIsLoading(false);
//           }
//         })
//         .catch((error) => {
//           onErrorSubmit(error.message);
//           setErrors({ general: "An error occurred. Please try again." });
//           setIsLoading(false);
//         });
//     } catch (error) {
//       onErrorSubmit(error.message);
//       setErrors({ general: "An error occurred. Please try again." });
//       setIsLoading(false);
//     }
//   };

//   const onSuccessSubmit = async (data) => {
//     const accessToken = data.payload;
//     const users = data.data;
//     setAuthData({ data });
//     localStorage.setItem("isLoggedIn", true);
//     setIsLoading(false);
//     navigate("/");
//   };

//   const onErrorSubmit = async (message) => {
//     setIsLoading(false);
//     setIsOpen(true);
//     setAlertType("error");
//     setMessage(message);
//   };

//   // =============

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Grid container component="main" sx={{ height: "100vh" }}>
//         <Grid
//           item
//           xs={false}
//           sm={4}
//           md={7}
//           sx={{
//             backgroundImage:
//               "url(https://source.unsplash.com/random?wallpapers)",
//             backgroundRepeat: "no-repeat",
//             backgroundColor: (t) =>
//               t.palette.mode === "light"
//                 ? t.palette.grey[50]
//                 : t.palette.grey[900],
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//         />
//         <Grid
//           className="flex justify-center items-center"
//           item
//           xs={12}
//           sm={8}
//           md={5}
//           component={Paper}
//           elevation={6}
//           square
//         >
//           <Box
//             sx={{
//               my: 8,
//               mx: 4,
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//               <LockOutlinedIcon />
//             </Avatar>
//             <Typography component="h1" variant="h5">
//               Log In
//             </Typography>
//             <Box
//               component="form"
//               noValidate
//               onSubmit={handleSubmit}
//               sx={{ mt: 1 }}
//             >
//               <FormInput
//                 label="Username"
//                 name="username"
//                 error={!!errors.username}
//                 helperText={errors.username}
//                 value={username}
//                 onChange={handleUsernameChange}
//               />
//               <FormInput
//                 label="Password"
//                 name="password"
//                 type="password"
//                 error={!!errors.password}
//                 helperText={errors.password}
//                 value={password}
//                 onChange={handlePasswordChange}
//               />
//               <LoadingButton
//                 className={`${
//                   isLoading ? "bg-gray-400 py-3" : "bg-primary py-3"
//                 }`}
//                 type="submit"
//                 fullWidth
//                 loading={isLoading}
//                 loadingPosition="end"
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2 }}
//               >
//                 <span>Log In</span>
//               </LoadingButton>
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 align="center"
//                 className="mt-5"
//               >
//                 {"Copyright © "}
//                 <span>Bottle Garden</span> {new Date().getFullYear()}
//                 {". "}
//               </Typography>
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>

//       <CustomizedSnackbars
//         isOpen={isOpen}
//         typeInfo={alertType}
//         messageInfo={message}
//         handleCloseSnackbar={() => setIsOpen(false)}
//       />
//     </ThemeProvider>
//   );
// }
